var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"wizard","tag":"section"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('base-material-wizard',{staticClass:"mx-auto",attrs:{"available-steps":_vm.availableSteps,"items":_vm.tabs},on:{"click:next":function($event){return _vm.next(valid)},"click:prev":function($event){_vm.tab--}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pb-12"},[_c('form',[_c('div',{staticClass:"text-center display-1 font-weight-light mb-6"},[_vm._v(" Let's start with basic information (with validation) ")]),_c('v-row',{staticClass:"mx-auto",staticStyle:{"max-width":"500px"},attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('input',{ref:"file",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onChange}}),_c('v-card',{staticClass:"mx-auto mt-0 d-inline-flex v-card--account",class:_vm.image ? 'success--text' : 'grey--text',attrs:{"outlined":"","height":"106","width":"106"},on:{"click":function($event){return _vm.$refs.file.click()}}},[(_vm.image)?_c('v-img',{attrs:{"src":_vm.image,"height":"100%","width":"100%"}}):_c('v-icon',{staticClass:"mx-auto",attrs:{"size":"96"}},[_vm._v(" mdi-account ")])],1),_c('div',{staticClass:"font-weight-bold grey--text"},[_vm._v("CHOOSE PICTURE")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"first"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":"First Name*","prepend-icon":"mdi-face","validate-on-blur":""},model:{value:(_vm.first),callback:function ($$v) {_vm.first=$$v},expression:"first"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"last"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":"Last Name*","prepend-icon":"mdi-account","validate-on-blur":""},model:{value:(_vm.last),callback:function ($$v) {_vm.last=$$v},expression:"last"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":"Email*","prepend-icon":"mdi-email","validate-on-blur":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)],1)]),_c('v-tab-item',{staticClass:"pb-12"},[_c('form',[_c('v-responsive',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('div',{staticClass:"text-center display-1 grey--text font-weight-light mb-6"},[_vm._v(" What are you doing? (checkboxes) ")]),_c('validation-provider',{attrs:{"rules":"required","name":"type"}},[_c('input',{attrs:{"type":"hidden"},domProps:{"value":_vm.stringAccount}})]),_c('v-item-group',{staticClass:"row",attrs:{"multiple":""},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}},_vm._l((_vm.accounts),function(account,i){return _c('v-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-6 mx-auto pa-10 d-inline-block v-card--account",class:active ? 'success--text' : 'grey--text',attrs:{"outlined":""},on:{"click":toggle}},[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(account.icon)}})],1),_c('div',{staticClass:"text-uppercase subtitle-2 text--primary",domProps:{"textContent":_vm._s(account.type)}})],1)]}}],null,true)})}),1)],1)],1)]),_c('v-tab-item',{staticClass:"pb-12"},[_c('div',{staticClass:"text-center display-1 grey--text font-weight-light mb-6"},[_vm._v(" Are you living in a nice area? ")]),_c('form',[_c('v-row',{staticClass:"mx-auto",staticStyle:{"max-width":"500px"},attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":"Street Name*","validate-on-blur":""},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":"Street Number*","validate-on-blur":""},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":"City*","validate-on-blur":""},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"autocomplete":Date.now(),"error-messages":errors,"items":_vm.states,"color":"secondary","label":"State*","validate-on-blur":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})]}}],null,true)})],1)],1)],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }